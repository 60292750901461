<template>
    <b-card>
        <div class="d-flex align-items-center justify-content-between table-header">
            <h2 class="text-primary mb-1">
                {{ typeof $route.meta.pageTitle === 'function' ? $route.meta.pageTitle() : $route.meta.pageTitle }}
                {{ namamenu }}
            </h2>
            <div class="d-flex align-items-center">
                <b-button variant="danger" @click="resetSearch()" class="ml-1">
                    <feather-icon icon="RefreshCcwIcon" />
                </b-button>

                <b-button class="ml-1" variant="dark"
                    @click.prevent="$router.push(`/paket/detailmenu/form/${$route.params.idpaket}/${$route.params.idmenu}`)">
                    <feather-icon icon="ListIcon" />
                    <span class="d-none d-md-inline">Back to List</span>
                </b-button>
            </div>
        </div>

        <b-row>
            <b-col md="12">
                <validation-provider #default="{ errors }" name="makanan" rules="required">
                    <b-form-group label="Nama Makanan" label-for="makanan" :state="errors.length > 0 ? false : null">
                        <v-select id="makanan" v-model="modelData.makanan"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="makananoptionfield"
                            label="text" placeholder="Pilih" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0]
                            }}</b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>
            </b-col>
        </b-row>

        <b-button class="d-flex align-items-center mt-50" variant="success" @click.prevent="handleCreate">
            <feather-icon class="mr-50" icon="SaveIcon" />
            Save
        </b-button>
    </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback } from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import uiSchema from './schema/FormMenuOptionalRequestUiSchema.json'
import schema from './schema/FormMenuOptionalRequestSchema.json'

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        vSelect,
        BFormInvalidFeedback,
        ToastificationContent,
    },
    data() {
        return {
            modelData: {
                makanan: '',
            },
            idPaket: 0,
            idMenu: 0,
            makananoptionfield: [],
            schema,
            uiSchema,
            loading: false,
            namamenu: ''
        }
    },
    mounted() {
        if (this.$route.params.idpaket && this.$route.params.idmenu) {
            this.getDetailData()
            this.getListDataMakanan()
        }
    },
    methods: {
        isLetter(e) {
            let char = String.fromCharCode(e.keyCode);
            if (/.*[0-9]/.test(char)) return true;
            else e.preventDefault();
        },

        resetSearch() {
            this.getListDataMakanan();
        },

        handleCreate() {
            if (!this.validateForm()) {
                return
            }

            const data = {
                kodemenu: this.idMenu,
                kodemakanan: this.modelData.makanan.value
            }

            this.$http.post('/api/menuoptional/create', data).then(res => {
                if (!res.data.status) {
                    this.$toast({
                        component: ToastificationContent, position: 'top-right',
                        props: {
                            title: 'Notification',
                            icon: 'SlashIcon',
                            variant: 'danger',
                            text: res.data.message,
                        },
                    });
                    return
                }

                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'CheckIcon',
                        variant: 'success',
                        text: '👋Berhasil menyimpan data',
                    },
                });

                setTimeout(() => {
                    this.$router.push(`/paket/detailmenu/form/${this.$route.params.idpaket}/${this.$route.params.idmenu}`);
                }, 1000);
            }).catch(e => {
                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'SlashIcon',
                        variant: 'danger',
                        text: 'Gagal menyimpan data' + e,
                    },
                });
            })
        },

        getDetailData() {
            this.$http.get(`/api/menu/detail/${this.$route.params.idmenu}`).then(res => {
                const response = res.data.data[0]
                this.idMenu = response.kodemenu
                this.namamenu = response.namamenu

            }).catch(e => {
                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'SlashIcon',
                        variant: 'danger',
                        text: 'Gagal mendapatkan data' + e,
                    },
                });
            })
        },

        getListDataMakanan() {
            const params = {
                page: 1,
                perPage: 100000
            };

            this.$http.get('/api/makanan/list', { params: params }).then(res => {
                const response = res.data.data;
                const filteredMakanan = response.filter(item => item.kelompok === this.namamenu);

                this.makananoptionfield = [];

                filteredMakanan.forEach(element => {
                    const makananOption = {
                        text: element.namamakanan,
                        value: element.kodemakanan
                    };

                    this.makananoptionfield.push(makananOption);
                });
            }).catch(e => {
                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'SlashIcon',
                        variant: 'danger',
                        text: 'Gagal mendapatkan data' + e,
                    },
                });
            });
        },

        validateForm() {
            if (this.modelData.makanan === '' || this.modelData.makanan === null) {
                this.$toast({
                    component: ToastificationContent, position: 'top-right',
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        variant: 'danger',
                        text: 'Silakan memilih makanan',
                    },
                });
                return false;
            }

            return true
        }
    }
}
</script>